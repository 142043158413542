import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Config from '../../Constants/index';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import TextInputField from '../../components/common/TextInputField';
import DateInputField from '../../components/common/DateInputField';
import { Redirect } from 'react-router-dom'
import Alert from '../../Component/Alert'
import { handleErrorResponse } from '../../utils/errorHandler'
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import DueDateOfCalibration from './DueDateOfCalibration';


const AddEntityForm = (props) => {

    /*Begin******************/

    const PAGE_TITLE = 'Hydrostatic And Seat Leak Test Report';
    const ENTITY_NAME = 'Cert03HydrostaticAndSeatLeakTest';
    const ParentEntityRefColName = 'equipment03HydrostaticAndSeatLeakTestId';
    const NEXTSTEP_ENTITY_NAME = '';

    const [entity, setEntity] = useState({
        //TODO
        certNumber01: '',
        certNumber02: '',
    });
        
    const mapEntityData = (data) => {
        setEntity((prevState) => ({
            ...prevState,
            certDate: data.certDate,
            certNumber: data.certNumber,
            testingConductedBy: data.testingConductedBy,
            reviewedBy: data.reviewedBy,
            witnessedBy: data.witnessedBy,
            signiture01CompanyTitle: data.signiture01CompanyTitle,
            signiture01PositionTitle: data.signiture01PositionTitle,
            signiture01Name: data.signiture01Name,
            signiture02CompanyTitle: data.signiture02CompanyTitle,
            signiture02PositionTitle: data.signiture02PositionTitle,
            signiture02Name: data.signiture02Name,
            signiture03CompanyTitle: data.signiture03CompanyTitle,
            signiture03PositionTitle: data.signiture03PositionTitle,
            signiture03Name: data.signiture03Name,
            signiture04CompanyTitle: data.signiture04CompanyTitle,
            signiture04PositionTitle: data.signiture04PositionTitle,
            signiture04Name: data.signiture04Name
        }));
    };

    /*end******************/

    const [id, setId] = React.useState(props.match.params.id);
    const [preStepEntityRef, setPreStepEntityRef] = React.useState(props.match.params.preStepEntityRef);
    debugger;
    const isEdit = props.match.params.id > 0;
    const [isRedirectToGrid, setIsRedirectToGrid] = React.useState(false)
    const [isRedirectToNextStep, setIsRedirectToNextStep] = React.useState(false)
    const [errorMessages, setErrorMessages] = useState([]);



    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);


    useEffect(() => {
        if (isEdit) {
            fetchEntity();
        }
    }, []);

    useEffect(() => {
        handleChange( ParentEntityRefColName ,preStepEntityRef);
    }, [preStepEntityRef]);



    const fetchEntity = async () => {
        try {
            const idToken = await getIdToken(user); // Get the token inside the method
            const response = await axios.get(
                `${Config.API_URL}${ENTITY_NAME}/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    },
                }
            );
            mapEntityData(response.data);
        } catch (error) {
            handleErrorResponse(['Failed to load data for editing. Please try again.'], setErrorMessages);
        }
    };


    const addEntity = async (onSuccess) => {
        try {
            const idToken = await getIdToken(user);

            const response = await axios.post(
                `${Config.API_URL}${ENTITY_NAME}`,
                entity,
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            );

            setId(response.data.id); // Update the ID after adding the entity
            setErrorMessages([]);
            if (onSuccess) onSuccess(response.data.id);
        } catch (error) {
            handleErrorResponse(error, setErrorMessages);
        }
    };

    const updateEntity = async (onSuccess) => {
        try {
            const idToken = await getIdToken(user); // Get the token inside the method
            const response = await axios.put(
                `${Config.API_URL}${ENTITY_NAME}/${id}`,
                entity,
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    },
                }
            );
            setErrorMessages([]);
            if (onSuccess) onSuccess(response.data.id);
        } catch (error) {
            handleErrorResponse(error, setErrorMessages);
        }
    };

    const handleSave = () => {
        const onSuccess = () => setIsRedirectToGrid(true);
        if (isEdit) {
            updateEntity(onSuccess);
        } else {
            addEntity(onSuccess);
        }
    };

    const handleSaveAndNext = () => {
        if (isEdit) {
            const onSuccess = () => { return; }
            updateEntity(onSuccess);
        } else {
            const onSuccess = () => setIsRedirectToNextStep(true);
            addEntity(onSuccess);
        }
    };

    const cancel = () => {
        setIsRedirectToGrid(true);
    }

    const autoFill = () => {
        setEntity((prevEntity) => ({
            ...prevEntity,
            signiture01CompanyTitle: "CITADEL ENGINEERING COMPANY",
            signiture01Name: "Shahriyar Majlesein",
            signiture01PositionTitle: "Citadel Representative",
    
            signiture02CompanyTitle: "IGCC",
            signiture02Name: "Govinda Adari",
            signiture02PositionTitle: "QA/QC Manager",
    
            signiture03CompanyTitle: "KENT",
            signiture03Name: "Joseph Thompson",
            signiture03PositionTitle: "QA/QC Supervisor",
    
            signiture04CompanyTitle: "Rumaila Operating Organization, IRAQ",
            signiture04Name: "Pragash Kannan",
            signiture04PositionTitle: "ROO Inspector",

            testingConductedBy : "Citadel Engineering Company",
            reviewedBy : "IGCC",
            witnessedBy : "KBR and ROO Representatives",
        }));
    };
    


    if (isRedirectToGrid)
        return <Redirect to={"/" + ENTITY_NAME + "/"} />

    if (isRedirectToNextStep)
        return <Redirect to={"/" + NEXTSTEP_ENTITY_NAME + "/Add/&PreStepEntityRef=" + id} />

    const handleChange = (field, value) => {
        setEntity({ ...entity, [field]: value });
    };



    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-6 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                                <h6 className="mb-0">New {PAGE_TITLE}</h6>
                        </div>

                        {isEdit == false &&
                                <div className="d-flex mt-4">
                                    <button type="button" class="btn btn-light m-0" onClick={autoFill} >
                                        Auto Fill
                                    </button>                                
                                </div>
                            }



                        <div className="card-body pt-2">



                            {/* Begin ****************** */}
                          
                            <DateInputField
                                label={"Cert Date"}
                                value={entity.certDate}
                                onChange={(value) => handleChange("certDate", value)}
                            />
                            <TextInputField
                                label={"Certificate Numer"}
                                value={entity.certNumber}
                                onChange={(value) => handleChange("certNumber", value)}
                            />
                            <TextInputField
                                label={"Testing Conducted By"}
                                value={entity.testingConductedBy}
                                onChange={(value) => handleChange("testingConductedBy", value)}
                            />
                            <TextInputField
                                label={"Reviewed By"}
                                value={entity.reviewedBy}
                                onChange={(value) => handleChange("reviewedBy", value)}
                            />
                            <TextInputField
                                label={"Witnessed By"}
                                value={entity.witnessedBy}
                                onChange={(value) => handleChange("witnessedBy", value)}
                            />
                            <TextInputField
                                label={"Signature 01 Company Title"}
                                value={entity.signiture01CompanyTitle}
                                onChange={(value) => handleChange("signiture01CompanyTitle", value)}
                            />
                            <TextInputField
                                label={"Signature 01 Name"}
                                value={entity.signiture01Name}
                                onChange={(value) => handleChange("signiture01Name", value)}
                            />
                            <TextInputField
                                label={"Signature 01 Position Title"}
                                value={entity.signiture01PositionTitle}
                                onChange={(value) => handleChange("signiture01PositionTitle", value)}
                            />
                            <TextInputField
                                label={"Signature 02 Company Title"}
                                value={entity.signiture02CompanyTitle}
                                onChange={(value) => handleChange("signiture02CompanyTitle", value)}
                            />
                            <TextInputField
                                label={"Signature 02 Name"}
                                value={entity.signiture02Name}
                                onChange={(value) => handleChange("signiture02Name", value)}
                            />
                            <TextInputField
                                label={"Signature 02 Position Title"}
                                value={entity.signiture02PositionTitle}
                                onChange={(value) => handleChange("signiture02PositionTitle", value)}
                            />
                            <TextInputField
                                label={"Signature 03 Company Title"}
                                value={entity.signiture03CompanyTitle}
                                onChange={(value) => handleChange("signiture03CompanyTitle", value)}
                            />
                            <TextInputField
                                label={"Signature 03 Name"}
                                value={entity.signiture03Name}
                                onChange={(value) => handleChange("signiture03Name", value)}
                            />
                            <TextInputField
                                label={"Signature 03 Position Title"}
                                value={entity.signiture03PositionTitle}
                                onChange={(value) => handleChange("signiture03PositionTitle", value)}
                            />
                            <TextInputField
                                label={"Signature 04 Company Title"}
                                value={entity.signiture04CompanyTitle}
                                onChange={(value) => handleChange("signiture04CompanyTitle", value)}
                            />
                            <TextInputField
                                label={"Signature 04 Name"}
                                value={entity.signiture04Name}
                                onChange={(value) => handleChange("signiture04Name", value)}
                            />
                            <TextInputField
                                label={"Signature 04 Position Title"}
                                value={entity.signiture04PositionTitle}
                                onChange={(value) => handleChange("signiture04PositionTitle", value)}
                            />
                            
                            {/* End ****************** */}

                            {errorMessages.length > 0 &&
                                <Alert messages={errorMessages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={handleSave}>
                                    Save
                                </button>
                                {((!isEdit && NEXTSTEP_ENTITY_NAME)) &&
                                    <button type="button" class="btn btn-primary m-0 ms-2" onClick={handleSaveAndNext}>
                                        Save & Next Step
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    );
};

export default AddEntityForm;
