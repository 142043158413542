import React from 'react';

const CheckboxField = ({ label, value, onChange }) => {

    const handleChange = (e) => {
        onChange(e.target.checked);
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="form-check my-3">
                    <input
                        type="checkbox"
                        className="form-check-input"
                        id={`checkbox-${label}`}
                        checked={value}
                        onChange={handleChange}
                    />
                    <label className="form-check-label" htmlFor={`checkbox-${label}`}>
                        {label}
                    </label>
                </div>
            </div>    
        </div>
    );
};

export default CheckboxField;
