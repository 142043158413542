import React, { useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css"; // Core grid CSS
import "ag-grid-community/dist/styles/ag-theme-alpine.css"; // Optional theme CSS
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import Config from '../../Constants/index';
import { Redirect } from 'react-router-dom';
import { handleErrorResponse } from '../../utils/errorHandler';
import axios from 'axios';
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';

const MyAgGrid = () => {

    /*Begin******************/

    const PAGE_TITLE = "File Entity";
    const ENTITY_NAME = 'FileEntity';
    const IS_STATUS_ENABLED = false;
    const IS_NEW_ENABLED = true;
    const REPORT_ROUTE = '';

    const columnDefs = [        
        { field: 'title', sortable: true, filter: true , width: '250px' },
        { field: 'fileName', sortable: true, filter: true , width: '250px' },
        { field: 'fileType', sortable: true, filter: true , width: '250px' }
    ];

    /*end******************/


    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [lastSelectedItem, setLastSelectedItem] = React.useState([]);
    const [isRedirecToInsert, setIsRedirecToInsert] = React.useState(false);
    const [isRedirecToEdit, setIsRedirecToEdit] = React.useState(false);
    const [errorMessages, setErrorMessages] = useState();

    
    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);

    const gridRef = React.useRef();




    
const deleteEntity = async (onSuccess) => {
    try {
        const idToken = await getIdToken(user); // Get the token inside the method
        await axios.delete(
            `${Config.API_URL}${ENTITY_NAME}/${lastSelectedItem.id}`,
            {
                headers: {
                    'Authorization': `Bearer ${idToken}`,
                },
            }
        );
        if (onSuccess) {
            setErrorMessages([]);
            closeModals();

            const gridApi = gridRef.current?.api;
            if (gridApi) gridApi.refreshInfiniteCache();
        }
    } catch (error) {
        handleErrorResponse(error, setErrorMessages);
        setIsErrorModalOpen(true);
    }
};

const datasource = {
    getRows: async (params) => {
        console.log('Requesting rows from server', params);

        const query = new URLSearchParams({
            startRow: params.startRow,
            endRow: params.endRow,
            sortModel: JSON.stringify(params.sortModel),
            filterModel: JSON.stringify(params.filterModel),
        });

        try {
            const idToken = await getIdToken(user); // Get the token inside the method
            const response = await axios.get(
                `${Config.API_URL}${ENTITY_NAME}?${query}`,
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    },
                }
            );
            params.successCallback(response.data.rows, response.data.total);
        } catch (error) {
            console.error(error);
            setErrorMessages(error, setErrorMessages);
            params.failCallback();
        }
    },
};

    

    const closeModals = () => {
        setIsDeleteModalOpen(false); // Close the modal
        setIsErrorModalOpen(false);
    }



    const onGridReady = (params) => {
        params.api.setDatasource(datasource);

        params.api.addEventListener('paginationChanged', () => {
            params.api.deselectAll(); // Clears all selections
            setLastSelectedItem([]); // Reset state for selected item
        });
    };

    const onRowSelected = (event) => {
        if (event.node.isSelected()) {
            console.log("Selected Row Data:", event.data);
            setLastSelectedItem(event.data);
        }
    };

    const insert = () => {
        setIsRedirecToInsert(true);
    }

    const edit = () => {
        setIsRedirecToEdit(true);
    }

    if (isRedirecToInsert) {
        return <Redirect to={'/' +  ENTITY_NAME + '/Add/'} />
    }

    if (isRedirecToEdit) {
        return <Redirect to={'/' +  ENTITY_NAME + '/Edit/' + lastSelectedItem.id} />
    }

    return (

        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

            {/* Navbar */}
            <NavBar />
            <div className="container-fluid py-4">


                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            {/* Card header */}
                            <div className="card-header pb-0">
                                <div className="d-lg-flex">
                                    <div>
                                        <h5 className="mb-0">{PAGE_TITLE}</h5>
                                        {/* <p className="text-sm mb-0">
              A lightweight, extendable, dependency-free javascript HTML table
              plugin.
            </p> */}
                                    </div>


                                    <div className="ms-auto my-auto mt-lg-0 mt-4">
                                        <div className="d-flex justify-content-end mt-4">
                                            {(IS_NEW_ENABLED) &&
                                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={insert}  >
                                                    + New
                                                </button>
                                            }
                                            {(IS_STATUS_ENABLED && lastSelectedItem.id) &&                                            
                                                <button type="button" class="btn btn-primary m-0 ms-2">
                                                    Change Status
                                                </button>
                                            }
                                            {(lastSelectedItem.id && REPORT_ROUTE) &&
                                                <a 
                                                    href={`${Config.API_URL}report/${REPORT_ROUTE}/?certId=${lastSelectedItem.id}&guid=${lastSelectedItem.reportGUID}`} 
                                                    className="btn btn-primary m-0 ms-2" 
                                                    target="_blank" 
                                                    rel="noopener noreferrer"
                                                >
                                                    Print 
                                                </a>
                                            }

                                            {(lastSelectedItem.id) &&
                                                <>
                                                    <button type="button" class="btn btn-outline-dark m-0 ms-2" onClick={edit} >
                                                        Edit
                                                    </button>
                                                    <button type="button" class="btn btn-outline-primary m-0 ms-2" onClick={() => setIsDeleteModalOpen(true)} >
                                                        Delete
                                                    </button>
                                                </>
                                            }
                                        </div>
                                    </div>



                                </div>
                            </div>
                            <div className="card-body px-0 pb-0">
                                <div
                                    className="ag-theme-alpine"
                                    style={{ height: 'calc(100vh - 200px)', margin: "auto" }}
                                >
                                    <AgGridReact
                                        ref={gridRef}
                                        columnDefs={columnDefs}
                                        rowSelection="single"
                                        onRowSelected={onRowSelected} // Callback for row selection
                                        rowModelType="infinite"
                                        cacheBlockSize={10} // Number of rows per request
                                        maxBlocksInCache={5} // Maximum number of blocks to keep in cache
                                        onGridReady={onGridReady}
                                        pagination={true} // Enable pagination
                                        paginationPageSize={10} // Number of rows per page
                                        getRowId={(params) => params.data.id} // Ensure this matches your row's `id` property
                                    ></AgGridReact>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />



            <div>




                {/* Modal */}
                {isDeleteModalOpen && (
                    <div
                        className="modal fade show"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                        <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5
                                        className="modal-title font-weight-normal"
                                        id="exampleModalLabel"
                                    >
                                        DELETE
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close text-dark"
                                        onClick={closeModals}
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">Are you sure you want to delete this item?</div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn bg-gradient-secondary"
                                        onClick={closeModals}
                                    >
                                        NO
                                    </button>
                                    <button type="button" className="btn bg-gradient-primary" onClick={deleteEntity} >
                                        YES
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

       
                {/* Modal */}
                {isErrorModalOpen && (
                    <div
                        className="modal fade show"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                    >
                        <div
                            className="modal-dialog modal-dialog-centered"
                            role="document"
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5
                                        className="modal-title font-weight-normal"
                                        id="exampleModalLabel"
                                    >
                                        Error
                                    </h5>
                                    <button
                                        type="button"
                                        className="btn-close text-dark"
                                        onClick={closeModals}
                                        aria-label="Close"
                                    >
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                {errorMessages.length > 0 && 
                <ul>
                {errorMessages.map((message)=>{
                    return (                       
                        <li>{message}</li>   
                    )
                })}
                </ul>
                }                                    
                                    </div>
                                <div className="modal-footer">
                                    <button
                                        type="button"
                                        className="btn bg-gradient-secondary"
                                        onClick={closeModals}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}


            </div>
        </main>




    );
};

export default MyAgGrid;
