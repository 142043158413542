import React from 'react';

const DropdownTextField = ({ label, value, onChange, options, required = false }) => {
    const handleChange = (e) => {
        onChange(e.target.value);
    };

    return (
        <div className="row">
            <div className="col-12">
                <div className="input-group input-group-static my-3">
                    <label>{label}</label>
                    <select
                        className="form-control"
                        value={value}
                        onChange={handleChange}
                        required={required}
                    >
                        <option value="" disabled>
                            Select an option
                        </option>
                        {options.map((option, index) => (
                            <option key={index} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
};

export default DropdownTextField;

