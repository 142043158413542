import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Config from '../../Constants/index';
import NavBar from '../../Navigation/NavBar';
import Footer from '../../Navigation/Footer';
import TextInputField from '../../components/common/TextInputField';
import DateInputField from '../../components/common/DateInputField';
import CheckboxField from '../../components/common/CheckBoxField';
import DropdownTextField from '../../components/common/DropdownTextField';

import { Redirect } from 'react-router-dom'
import Alert from '../../Component/Alert'
import { handleErrorResponse } from '../../utils/errorHandler'
import { getAuth, onAuthStateChanged, getIdToken } from "firebase/auth";
import { useAuthState } from 'react-firebase-hooks/auth';
import UserDefaultCustomer from '../../Component/Calibration/UserDefaultCustomer';
import DueDateOfCalibration from './DueDateOfCalibration';


const AddEntityForm = (props) => {

    /*Begin******************/

    const PAGE_TITLE = 'Scope Of Work';
    const ENTITY_NAME = 'Cert01ScopeOfWork';
    const ParentEntityRefColName = 'cert01ValveInspectionId';
    const NEXTSTEP_ENTITY_NAME = '';
    const IS_AUTOFILL_ENABLED = false;    

    const inspectionOptions = [
        "Hydrostatic Test",
        "Lifting Equipment Inspection",
        "Marking Inspection",
        "Mill Inspection",
        "NTD/Witnessing/Supervising",
        "On-hire/Off-hire Inspection",
        "Packing Inspection",
        "Pre-Inspection Meeting",
        "Pre-Shipment Inspection",
        "Quality Testing",
        "Welders Performance Test",
        "Welding Inspection",
        "Welding Procedures Qualification",
        "Visual Inspection",
    ];

    const [entity, setEntity] = useState({
        //certNumber01: '',        
    });
        
    const mapEntityData = (data) => {
        setEntity((prevState) => ({
            ...prevState,
            ...data,
          }));
    };

    /*end******************/
    const [id, setId] = React.useState(props.match.params.id);
    const [preStepEntityRef, setPreStepEntityRef] = React.useState(props.match.params.preStepEntityRef);
    const isEdit = props.match.params.id > 0;
    const [isRedirectToGrid, setIsRedirectToGrid] = React.useState(false)
    const [isRedirectToNextStep, setIsRedirectToNextStep] = React.useState(false)
    const [errorMessages, setErrorMessages] = useState([]);

    const auth = getAuth();
    const [user, loading, error] = useAuthState(auth);


    useEffect(() => {
        if (isEdit) {
            fetchEntity();
        }
    }, []);

    useEffect(() => {
        handleChange( ParentEntityRefColName ,preStepEntityRef);
    }, [preStepEntityRef]);



    const fetchEntity = async () => {
        try {
            const idToken = await getIdToken(user); // Get the token inside the method
            const response = await axios.get(
                `${Config.API_URL}${ENTITY_NAME}/${id}`,
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    },
                }
            );
            mapEntityData(response.data);
        } catch (error) {
            handleErrorResponse(['Failed to load data for editing. Please try again.'], setErrorMessages);
        }
    };


    const addEntity = async (onSuccess) => {
        try {
            const idToken = await getIdToken(user);

            const response = await axios.post(
                `${Config.API_URL}${ENTITY_NAME}`,
                entity,
                {
                    headers: {
                        'Authorization': 'Bearer ' + idToken
                    }
                }
            );

            setId(response.data.id); // Update the ID after adding the entity
            setErrorMessages([]);
            if (onSuccess) onSuccess(response.data.id);
        } catch (error) {
            handleErrorResponse(error, setErrorMessages);
        }
    };

    const updateEntity = async (onSuccess) => {
        try {
            const idToken = await getIdToken(user); // Get the token inside the method
            const response = await axios.put(
                `${Config.API_URL}${ENTITY_NAME}/${id}`,
                entity,
                {
                    headers: {
                        'Authorization': `Bearer ${idToken}`,
                    },
                }
            );
            setErrorMessages([]);
            if (onSuccess) onSuccess(response.data.id);
        } catch (error) {
            handleErrorResponse(error, setErrorMessages);
        }
    };

    const handleSave = () => {
        const onSuccess = () => setIsRedirectToGrid(true);
        if (isEdit) {
            updateEntity(onSuccess);
        } else {
            addEntity(onSuccess);
        }
    };

    const handleSaveAndNext = () => {
        if (isEdit) {
            const onSuccess = () => { return; }
            updateEntity(onSuccess);
        } else {
            const onSuccess = () => setIsRedirectToNextStep(true);
            addEntity(onSuccess);
        }
    };

    const cancel = () => {
        setIsRedirectToGrid(true);
    }

    const autoFill = () => {
        setEntity((prevEntity) => ({
            ...prevEntity,
            //signiture01CompanyTitle: "CITADEL ENGINEERING COMPANY",
        }));
    };
    


    if (isRedirectToGrid)
        return <Redirect to={"/" + ENTITY_NAME + "/"} />

    if (isRedirectToNextStep)
        return <Redirect to={"/" + NEXTSTEP_ENTITY_NAME + "/Add/&PreStepEntityRef=" + id} />

    const handleChange = (field, value) => {
        setEntity({ ...entity, [field]: value });
    };



 


    return (
        <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">

            <NavBar />
            <div className="container-fluid py-4">
                <div className="col-lg-6 col-12 mx-auto position-relative">
                    <div className="card">
                        <div className="card-header p-3 pt-2">
                            <div className="icon icon-lg icon-shape bg-gradient-dark shadow text-center border-radius-xl mt-n4 me-3 float-start">
                                <i class="fa fa fa-wrench fa-fw" aria-hidden="true"></i>
                            </div>
                                <h6 className="mb-0">New {PAGE_TITLE} {preStepEntityRef}</h6>
                        </div>

                        {((isEdit == false) && (IS_AUTOFILL_ENABLED)) && 
                                <div className="d-flex mt-4">
                                    <button type="button" class="btn btn-light m-0" onClick={autoFill} >
                                        Auto Fill
                                    </button>                                
                                </div>
                            }



                        <div className="card-body pt-2">

                            {/* Begin ****************** */}                          
                            <TextInputField
                                label={"Title"}
                                value={entity.title}
                                onChange={(value) => handleChange("title", value)}
                            />

                            <DropdownTextField
                                label={"Title"}
                                value={entity.title}
                                onChange={(value) => handleChange("title", value)}
                                options={["Option 1", "Option 2", "Option 3"]}
                            />



                            <CheckboxField
                                label="Requested Inspection"
                                value={entity.requestedInspection}
                                onChange={(value) => handleChange("requestedInspection", value)}
                            />
                            <CheckboxField
                                label="Requested Witness"
                                value={entity.requestedWitness}
                                onChange={(value) => handleChange("requestedWitness", value)}
                            />
                            <CheckboxField
                                label="Requested Review"
                                value={entity.requestedReview}
                                onChange={(value) => handleChange("requestedReview", value)}
                            />
                            <CheckboxField
                                label="Performed Inspection"
                                value={entity.performedInspection}
                                onChange={(value) => handleChange("performedInspection", value)}
                            />
                            <TextInputField
                                label={"Comment"}
                                value={entity.scopeOfWorkComment}
                                onChange={(value) => handleChange("scopeOfWorkComment", value)}
                            />
                       
                          
                            {/* End ****************** */}

                            {errorMessages.length > 0 &&
                                <Alert messages={errorMessages}></Alert>}
                            <div className="d-flex justify-content-end mt-4">
                                <button type="button" class="btn btn-light m-0" onClick={cancel}>
                                    Cancel
                                </button>
                                <button type="button" class="btn bg-gradient-dark m-0 ms-2" onClick={handleSave}>
                                    Save
                                </button>
                                {((!isEdit && NEXTSTEP_ENTITY_NAME)) &&
                                    <button type="button" class="btn btn-primary m-0 ms-2" onClick={handleSaveAndNext}>
                                        Save & Next Step
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </main >
    );
};

export default AddEntityForm;
